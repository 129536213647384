<template>
<div id="app">
<v-app>

<!-- step1. 担当医選択 -->
<template v-if="step_flags[1]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">
        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">担当医選択</div>
            <v-progress-linear value="15" background-color="blue-grey lighten-4" color="#8CC11E"></v-progress-linear>
        </div>

        <!-- 内容 -->
        <div class="white_box mb-6">
            <div class="title mb-2">予約の際はお読みください</div>
            <div class="text-left">WEB予約は本予約となります。
予約の時間を選択していただき、そのお時間にご来院ください。ご予約の際は曜日・時間をお間違えのないよう、お願い申し上げます。<br><br>

★初めてお越しの患者様は、ご予約時間の５分前までにご来院くださいますようお願いいたします。</div>
        </div>

        <div class="midasi white--text text-left">担当医を選択してください。初めての方は「初診」を選択してください。</div>
        <v-radio-group
            v-model="data.doctor"
            class="d-flex align-center mb-4"
            :rules="[v => !!v || '選択してください.']"
        >
            <v-radio
            v-for="v in dic_doctor"
            color="#8CC11E"
            :key="v.doctor"
            :label="v.doctor"
            :value="v.id"
            ></v-radio>
        </v-radio-group>

        <v-btn type="submit" color="#8CC11E" class="white--text">診察内容選択に進む</v-btn>


        <div v-if="cancel_code" class="mt-4">
            <v-btn @click="pre_cancel()" color="#8CC11E" class="white--text">キャンセルする</v-btn>
        </div>

    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step1. -->



<!-- step2. 診療内容選択 -->
<template v-else-if="step_flags[2]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>

<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">
        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">診察内容選択</div>
            <v-progress-linear value="30" background-color="blue-grey lighten-4" color="#8CC11E"></v-progress-linear>
        </div>

        <div class="midasi white--text text-left mb-6">診療内容を選択してください。</div>

        <!-- 内容 -->
        <div :class="[shinryounaiyou[0] ? 'white_box__active':'white_box', 'mb-6']" @click="toggleClass(0)">
            <div class="title mb-2">治療</div>
            <div class="text-left">痛みがある・詰め物がとれた・顎が痛い・入れ歯が合わない方はこちら</div>
        </div>
        <div :class="[shinryounaiyou[1] ? 'white_box__active':'white_box', 'mb-6']" @click="toggleClass(1)">
            <div class="title mb-2">検診・クリーニング</div>
            <div class="text-left">定期検診・クリーニングを希望される方はこちら</div>
        </div>
        <div :class="[shinryounaiyou[2] ? 'white_box__active':'white_box', 'mb-6']" @click="toggleClass(2)">
            <div class="title mb-2">インプラント・矯正等の相談</div>
            <div class="text-left">インプラント治療・矯正治療を希望される方はこちら</div>
        </div>

        <!-- ボタングループ -->
        <v-row justify="space-between">
            <v-col cols="12" class="text-center">
            <v-btn type="submit" color="#8CC11E" class="white--text" :disabled="btn_step2_disabled">来院希望日時選択に進む</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
            <v-btn @click="back()" outlined color="#8CC11E" class="">前画面に戻る</v-btn>
            </v-col>
        </v-row>

    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step2. -->




<!-- step3. 予約日時入力 -->
<template v-else-if="step_flags[3]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>
<v-container fluid class="step3">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">

        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">予約日時入力</div>
            <v-progress-linear value="45" background-color="blue-grey lighten-4" color="#8CC11E"></v-progress-linear>
        </div>

        <div class="midasi white--text text-left mb-6">ご来院の希望日時を選択してください。
        <br>●▲の箇所が予約可能日時です。</div>



           <v-row justify="space-between" class="text_green mb-1">
               <v-col cols="6" class="text-left day_text">{{ day_text }}</v-col>
               <v-col cols="6" class="pb-0">
                   <v-row justify="end" class="text_green mb-1">
                       <v-btn width="36" color="#8CC11E" class="white--text mr-2" @click="modoru()"><v-icon class="mr-2">mdi-chevron-left</v-icon></v-btn>
                       <v-btn color="#8CC11E" class="kyoubtn white--text mr-2" @click="kyou()">今日</v-btn>
                       <v-btn width="36" color="#8CC11E" class="white--text" @click="susumu()"><v-icon class="mr-2">mdi-chevron-right</v-icon></v-btn>
                   </v-row>
               </v-col>
           </v-row>

           <v-row justify="space-between" class="calendar text-center text_green mb-6">
                <!-- カレンダー -->
                <v-col cols="12" align-self="end" class="">
                <v-row justify="center" class="text-center">
                    <v-col cols="1.5" class="jikanjiku">
                        <div class="day"> </div>
                        <div class="masu" v-for="(v,i) in jikanjiku" :key="i">{{ v }}</div>
                    </v-col>
                    <v-col cols="1.5" class="oneday" v-for="(row, i) in week_part" :key="i">
                        <div class="day" v-html="row.day"></div>

 

                        <div v-for="(v, ii) in row.masu" :id="row.req+'_'+ii" ref="masu" :class="{'masu':true, 'active': masu_selected==(row.req+'_'+ii)}" :key="ii" @click.stop.prevent.self="masuClick(row.req, i, ii, v, $event, false)">
                            <span v-if="v=='TEL'">TEL</span>
                            <img v-else  @click.stop.prevent.self="masuClick(row.req, i, ii, v, $event, true)" class="img" :src="print_svg(v, (row.req+'_'+ii))" style="" />
                        </div><!-- ii=0, 1, 2 -->
                    </v-col>
                </v-row>
                </v-col>
            </v-row>




        <div class="text-left text_green mb-6">
            <div><img src="/img/maru.svg" class="icon_svg">：予約可</div>
            <div><img src="/img/sankaku.svg" class="icon_svg">：残りわずか</div>
            <div><img src="/img/batu.svg" class="icon_svg">：ネット予約不可</div>
            <div>TEL：お電話にてお問い合わせください。</div>
<!--            <div>休：定休日</div>  -->
            <div>※状況によっては順番が前後したりご案内にお時間がかかる場合がございます。</div>
        </div>


        <!-- ボタングループ -->
        <v-row justify="space-between" class="mb-2">
            <v-col cols="12" class="text-center">
            <v-btn type="submit" color="#8CC11E" class="white--text" :disabled="btn_step3_disabled">問診内容選択に進む</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
            <v-btn @click="back()" outlined color="#8CC11E" class="">前画面に戻る</v-btn>
            </v-col>
        </v-row>


    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step3. -->


<!-- step4. 問診内容選択 -->
<template v-else-if="step_flags[4]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">

        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">問診内容選択</div>
            <v-progress-linear value="60" background-color="blue-grey lighten-4" color="#8CC11E"></v-progress-linear>
        </div>


        <div class="midasi white--text text-left mb-4">来院の理由を教えてください（複数選択可）</div>
        <v-col cols="12" >
            <div v-for="v in raiin_reasons" :key="v.id" class="text_green">
                <v-checkbox
                    v-model="data.raiin_reasons"
                    :value="v.name"
                    :label="v.name"
                    color="#8CC11E"
                />
            </div>
        </v-col>


        <div class="midasi white--text text-left mb-4">診療についてのご希望（複数選択可）</div>
        <v-col cols="12" >
            <div v-for="v in shinsatu_kibou" :key="v.id" class="text_green">
                <v-checkbox
                    v-model="data.shinsatu_kibou"
                    :value="v.name"
                    :label="v.name"
                    color="#8CC11E"
                />
            </div>
        </v-col>


        <div class="midasi white--text text-left">現在、他の病院に通院中ですか？</div>
        <v-col cols="12" style="height: 120px;">
            <v-radio-group
            v-model="data.hoka_tuuinn"
            class="d-flex align-center mb-4"
            >
                <v-radio
                    color="#8CC11E"
                    label="はい"
                    value="はい"
                ></v-radio>
                <v-radio
                    color="#8CC11E"
                    label="いいえ"
                    value="いいえ"
                ></v-radio>
            </v-radio-group>
        </v-col>

        <div class="midasi white--text text-left">現在、服用中のお薬はありますか？</div>
        <v-col cols="12" style="height: 120px;">
            <v-radio-group
            v-model="data.okusuri"
            class="d-flex align-center mb-4"
            >
                <v-radio
                    color="#8CC11E"
                    label="はい"
                    value="はい"
                ></v-radio>
                <v-radio
                    color="#8CC11E"
                    label="いいえ"
                    value="いいえ"
                ></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" style="padding:0; margin-bottom: 20px; font-size:14px;">
        ※「はい」の場合、お薬手帳をご持参ください
        </v-col>

        <div class="midasi white--text text-left">薬の副作用やアレルギーはありますか？</div>
        <v-col cols="12" style="height: 120px;">
            <v-radio-group
            v-model="data.allergies"
            class="d-flex align-center mb-4"
            >
                <v-radio
                    color="#8CC11E"
                    label="はい"
                    value="はい"
                ></v-radio>
                <v-radio
                    color="#8CC11E"
                    label="いいえ"
                    value="いいえ"
                ></v-radio>
            </v-radio-group>
        </v-col>

        <div class="midasi white--text text-left">これまで歯科治療の麻酔で気分が悪くなったことはありますか？</div>
        <v-col cols="12" style="height: 120px;">
            <v-radio-group
            v-model="data.masui"
            class="d-flex align-center mb-4"
            >
                <v-radio
                    color="#8CC11E"
                    label="はい"
                    value="はい"
                ></v-radio>
                <v-radio
                    color="#8CC11E"
                    label="いいえ"
                    value="いいえ"
                ></v-radio>
            </v-radio-group>
        </v-col>

        <div class="midasi white--text text-left">妊娠の可能性はありますか？または授乳中ですか？</div>
        <v-col cols="12" >
            <v-radio-group
            v-model="data.ninshin"
            class="d-flex align-center mb-4"
            >
                                <v-radio
                                    color="#8CC11E"
                                    label="はい"
                                    value="はい"
                                ></v-radio>
                                <v-radio
                                    color="#8CC11E"
                                    label="妊娠中"
                                    value="妊娠中"
                                ></v-radio>
                                <v-radio
                                    color="#8CC11E"
                                    label="授乳中"
                                    value="授乳中"
                                ></v-radio>
                                <v-radio
                                    color="#8CC11E"
                                    label="いいえ"
                                    value="いいえ"
                                ></v-radio>
            </v-radio-group>
        </v-col>



        <div class="midasi white--text text-left mb-4">ご来院のきっかけについて教えてください（複数選択可）</div>
        <v-col cols="12" >
            <div v-for="v in raiin_from" :key="v.id" class="text_green">
                <v-checkbox
                    v-model="data.raiin_from"
                    :value="v.name"
                    :label="v.name"
                    color="#8CC11E"
                />
            </div>
        </v-col>

        <div class="midasi white--text text-left mb-4">その他、ご要望・ご質問などございましたら、ご自由にご記入ください</div>
        <v-col cols="12" >
            <v-textarea
            v-model="data.youbou"
            outlined></v-textarea>
        </v-col>

        <!-- ボタングループ -->
        <v-row justify="space-between" class="mb-2">
            <v-col cols="12" class="text-center">
            <v-btn type="submit" color="#8CC11E" class="white--text">予約情報入力に進む</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
            <v-btn @click="back()" outlined color="#8CC11E" class="">前画面に戻る</v-btn>
            </v-col>
        </v-row>



    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step4. -->



<!-- step5. 予約情報入力 -->
<template v-else-if="step_flags[5]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">

        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">予約情報入力</div>
            <v-progress-linear value="75" background-color="blue-grey lighten-4" color="#8CC11E"></v-progress-linear>
        </div>

        <div class="midasi white--text text-left">診察券番号を入力してください (任意)</div>
        <v-col cols="12">
            <v-text-field
            v-model="data.bangou"
            color="#8CC11E"
            label="診察券番号"
            @blur="do_birthday"
            outlined></v-text-field>
        </v-col>


        <div class="midasi white--text text-left">生年月日を入力してください (必須)</div>
        <v-col cols="12">
              <v-row>
        <v-col cols="5" class="pt-6 mybirth">
          <v-select label="年" :items="years" v-model="birthday_year" @change="do_birthday" filled dense
            :rules="[v => !!v || '必須項目です.']"
          ></v-select>
        </v-col>
        <v-col cols="3.5" class="pl-0 pt-6 mybirth">
          <v-select label="月" :items="months" v-model="birthday_month" @change="do_birthday" filled dense
            :rules="[v => !!v || '必須項目です.']"
          ></v-select>
        </v-col>
        <v-col cols="3.5" class="pl-0 pr-0 pt-6 mybirth">
          <v-select label="日" :items="days" v-model="birthday_day" @change="do_birthday" filled dense
            :rules="[v => !!v || '必須項目です.']"
          ></v-select>
        </v-col>
      </v-row>
        </v-col>

        <div class="midasi white--text text-left">メールアドレスを入力してください (必須)</div>
        <v-col cols="12">
            <v-text-field
            :rules="[v => !!v || '必須項目です.']"
            v-model="data.mail"
            label="メールアドレス"
            outlined></v-text-field>
        </v-col>



        <div class="midasi white--text text-left mb-4">名前を漢字で入力してください (必須)</div>
        <v-row justify="space-between">
         <v-col cols="6">
                <v-text-field v-model="data.sei" label="姓" outlined :rules="[v => !!v || '必須項目です.']"></v-text-field>
         </v-col>
         <v-col cols="6">
                <v-text-field v-model="data.mei" label="名" outlined :rules="[v => !!v || '必須項目です.']"></v-text-field>
         </v-col>
        </v-row>


        <div class="midasi white--text text-left mb-4">名前をカタカナで入力してください (必須)</div>
        <v-row justify="space-between">
         <v-col cols="6">
                <v-text-field v-model="data.sei_kana" label="セイ" outlined :rules="[v => !!v || '必須項目です.']"></v-text-field>
         </v-col>
         <v-col cols="6">
                <v-text-field v-model="data.mei_kana" label="メイ" outlined :rules="[v => !!v || '必須項目です.']"></v-text-field>
         </v-col>
        </v-row>


        <div class="midasi white--text text-left">性別を選択してください (必須)</div>
        <v-col cols="12">

                            <v-radio-group
                            v-model="data.sex"
                            row
                            class="d-flex align-center mb-4"
                            :rules="[v => !!v || '必須項目です.']"
                            >
                                <v-radio
                                    color="#8CC11E"
                                    label="男性"
                                    value="M"
                                ></v-radio>
                                <v-radio
                                    color="#8CC11E"
                                    label="女性"
                                    value="F"
                                ></v-radio>
                            </v-radio-group>
        </v-col>


        <div class="midasi white--text text-left">固定電話番号を入力してください (任意)</div>
        <v-col cols="12">
                            <v-text-field
                            v-model="data.tel"
                            label="固定電話"
                            placeholder="0312345678"
                            :persistent-placeholder="true"
                            outlined></v-text-field>
        </v-col>
        <div class="midasi white--text text-left">携帯電話番号を入力してください (必須)</div>
        <v-col cols="12">
                            <v-text-field
                            :rules="[v => !!v || '必須項目です.']"
                            v-model="data.phone"
                            label="携帯電話"
                            placeholder="0312345678"
                            :persistent-placeholder="true"
                            outlined></v-text-field>
        </v-col>



        <!-- ボタングループ -->
        <v-row justify="space-between" class="mb-2">
            <v-col cols="12" class="text-center">
            <v-btn type="submit" color="#8CC11E" class="white--text">予約内容確認に進む</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
            <v-btn @click="back()" outlined color="#8CC11E" class="">前画面に戻る</v-btn>
            </v-col>
        </v-row>

    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step5. -->



<!-- step6. 予約内容確認 -->
<template v-else-if="step_flags[6]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>
<v-container fluid class="confirmation">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">


        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">予約内容確認</div>
            <v-progress-linear value="90" background-color="blue-grey lighten-4" color="#8CC11E"></v-progress-linear>
        </div>


            <v-row class="">
              <v-col class="text-left font-weight-bold" cols="5">クリニック</v-col>
              <v-col class="text-left" cols="7" >
                にしはらクリニック
              </v-col>
              <v-col class="text-left font-weight-bold" cols="5">診察内容</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.shinryounaiyou }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">担当</v-col>
              <v-col class="text-left" cols="7" >
                {{ doctor_name }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">来院希望日時</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.date.replace(/-/mg, '/') }}({{weekday}}) {{ data.start }}~{{ data.end }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">来院理由</v-col>
              <v-col class="text-left" cols="7" >
                <div v-for="(row, index) in data.raiin_reasons" :key="index">{{ row }}</div>
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">診療希望</v-col>
              <v-col class="text-left" cols="7" >
                <div v-for="(row, index) in data.shinsatu_kibou" :key="index">{{ row }}</div>
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">通院中の病院</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.hoka_tuuinn }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">服用中のお薬</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.okusuri }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">副作用・アレルギー</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.allergies }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">麻酔副作用</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.masui }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">妊娠・授乳</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.ninshin }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">来院きっかけ</v-col>
              <v-col class="text-left" cols="7" >
                <div v-for="(row, index) in data.raiin_from" :key="index">{{ row }}</div>
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">ご要望・ご質問</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.youbou }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">お名前(漢字)</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.sei }} {{ data.mei }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">お名前(カタカナ)</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.sei_kana }} {{ data.mei_kana }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">生年月日</v-col>
              <v-col class="text-left" cols="7" >
                {{ getBirthdayText() }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">性別</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.sex==='M' ? '男性' : '女性' }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">固定電話番号</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.tel }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">携帯電話番号</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.phone }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">メールアドレス</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.mail }}
              </v-col>
            </v-row>




        <!-- ボタングループ -->
        <v-row justify="space-between" class="mb-2">
            <v-col cols="12" class="text-center">
            <v-btn type="submit" color="#8CC11E" class="white--text">予約を申し込む</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
            <v-btn @click="back()" outlined color="#8CC11E" class="">入力内容を修正する</v-btn>
            </v-col>
        </v-row>
    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step6. -->


<!-- step7. 予約完了 -->
<template v-else-if="step_flags[7]">
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">


        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">予約完了</div>
            <v-progress-linear value="100" background-color="blue-grey lighten-4" color="light-green darken-1"></v-progress-linear>
        </div>

        <div cols="12" class="font-weight-bold light-green--text text--darken-1 mt-10 mb-6">予約申し込みを受け付けました</div>
        <div cols="12" class="grey--text text--darken-2 text-left mb-10">
        ご登録いただいたメールアドレス宛に予約確認メールを送信しております。<br>
        予約内容の確認、変更、キャンセルなどは、
        メールから行うことができます。<br>
        ※「@nishiharacb-dentalclinic.com」のドメインから、予約確認メールが送信されますので受信設定をお願い申し上げます。</div>


        <!-- ボタングループ -->
        <v-row justify="space-between">
            <v-col cols="12" class="text-center">
            <v-btn @click="toTop()" color="light-green darken-1" class="white--text">トップページへ</v-btn>
            </v-col>
        </v-row>
    </v-col><!-- wrap -->
</v-row>
</v-container>
</template><!-- step7. -->


<!-- step8. キャンセル完了 -->
<template v-else-if="step_flags[8]">
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">


        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">キャンセル完了</div>
            <v-progress-linear value="100" background-color="blue-grey lighten-4" color="light-green darken-1"></v-progress-linear>
        </div>

        <div cols="12" class="font-weight-bold light-green--text text--darken-1 mt-10 mb-6">予約をキャンセルしました</div>
<!--
        <div cols="12" class="grey--text text--darken-2 text-left mb-10">
            予約をキャンセルしました。
        </div>
        -->


        <!-- ボタングループ -->
        <v-row justify="space-between">
            <v-col cols="12" class="text-center">
            <v-btn @click="toTop()" color="light-green darken-1" class="white--text">トップページへ</v-btn>
            </v-col>
        </v-row>
    </v-col><!-- wrap -->
</v-row>
</v-container>
</template><!-- step8. -->

<!-- step9. キャンセル済み -->
<template v-else-if="step_flags[9]">
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">

        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">キャンセル済みです</div>
            <v-progress-linear value="100" background-color="blue-grey lighten-4" color="light-green darken-1"></v-progress-linear>
        </div>

        <div cols="12" class="font-weight-bold light-green--text text--darken-1 mt-10 mb-6">既にキャンセル済みです</div>
<!--
        <div cols="12" class="grey--text text--darken-2 text-left mb-10">
            予約をキャンセルしました。
        </div>
        -->


        <!-- ボタングループ -->
        <v-row justify="space-between">
            <v-col cols="12" class="text-center">
            <v-btn @click="toTop()" color="light-green darken-1" class="white--text">トップページへ</v-btn>
            </v-col>
        </v-row>
    </v-col><!-- wrap -->
</v-row>
</v-container>
</template><!-- step9. -->

<!-- step10. 締め切り過ぎ -->
<template v-else-if="step_flags[10]">
<v-container fluid class="main">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">


        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">キャンセル可能な時間を過ぎています</div>
            <v-progress-linear value="100" background-color="blue-grey lighten-4" color="light-green darken-1"></v-progress-linear>
        </div>

        <div cols="12" class="font-weight-bold light-green--text text--darken-1 mt-10 mb-6 text-left">※予約キャンセルは予約時間の1時間前まで可能です。</div>
<!--
        <div cols="12" class="grey--text text--darken-2 text-left mb-10">
            予約をキャンセルしました。
        </div>
        -->


        <!-- ボタングループ -->
        <v-row justify="space-between">
            <v-col cols="12" class="text-center">
            <v-btn @click="toTop()" color="light-green darken-1" class="white--text">トップページへ</v-btn>
            </v-col>
        </v-row>
    </v-col><!-- wrap -->
</v-row>
</v-container>
</template><!-- step10. -->




<!-- step11. キャンセル確認 -->
<template v-else-if="step_flags[11]">
<v-form
   ref="form"
   v-model="valid"
   @submit.prevent="submit"
>
<v-container fluid class="confirmation">
<v-row justify="space-between">
    <v-col cols="12" class="text-center">


        <!-- タイトル -->
        <div class="mb-5">
            <div class="h1 mb-4">予約内容確認</div>
            <v-progress-linear value="90" background-color="blue-grey lighten-4" color="light-green darken-1"></v-progress-linear>
        </div>


            <v-row class="">
              <v-col class="text-left font-weight-bold" cols="5">クリニック</v-col>
              <v-col class="text-left" cols="7" >
                にしはらクリニック
              </v-col>
              <v-col class="text-left font-weight-bold" cols="5">診察内容</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.shinryounaiyou }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">担当</v-col>
              <v-col class="text-left" cols="7" >
                {{ doctor_name }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">来院希望日時</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.date.replace(/-/mg, '/') }}({{weekday}}) {{ data.start }}~{{ data.end }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">来院理由</v-col>
              <v-col class="text-left" cols="7" >
                <div v-for="(row, index) in data.raiin_reasons" :key="index">{{ row }}</div>
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">診療希望</v-col>
              <v-col class="text-left" cols="7" >
                <div v-for="(row, index) in data.shinsatu_kibou" :key="index">{{ row }}</div>
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">通院中の病院</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.hoka_tuuinn }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">服用中のお薬</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.okusuri }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">副作用・アレルギー</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.allergies }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">麻酔副作用</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.masui }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">妊娠・授乳</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.ninshin }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">来院きっかけ</v-col>
              <v-col class="text-left" cols="7" >
                <div v-for="(row, index) in data.raiin_from" :key="index">{{ row }}</div>
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">ご要望・ご質問</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.youbou }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">お名前(漢字)</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.sei }} {{ data.mei }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">お名前(カタカナ)</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.sei_kana }} {{ data.mei_kana }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">生年月日</v-col>
              <v-col class="text-left" cols="7" >
                {{ getBirthdayText() }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">性別</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.sex==='M' ? '男性' : '女性' }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">固定電話番号</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.tel }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">携帯電話番号</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.phone }}
              </v-col>

              <v-col class="text-left font-weight-bold" cols="5">メールアドレス</v-col>
              <v-col class="text-left" cols="7" >
                {{ data.mail }}
              </v-col>
            </v-row>




        <!-- ボタングループ -->
        <v-row justify="space-between">
            <v-col cols="12" class="text-center">
            <v-btn @click="cancel()" color="light-green darken-1" class="white--text">キャンセルする</v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
            <v-btn @click="back()" outlined color="light-green darken-1" class="">戻る</v-btn>
            </v-col>
        </v-row>
    </v-col><!-- wrap -->
</v-row>
</v-container>
</v-form>
</template><!-- step11. -->


</v-app>
</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import mycolor from '@/mycolor.js';
/* eslint-disable */

export default {
components:{
},

  beforeRouteLeave(to, from, next) {
    if (this.confirm() === false) return;
    next();
  },


computed:{
    years() {
      const years = []
      for (let year = 1930; year <= new Date().getFullYear(); year++) {
        years.push(year+'')
      }
      return years
    },
    months() {
      const months = [...Array(12)].map((ele, i) => ((i + 1)+'').padStart(2, '0'))
      return months
    },
    days() {
      let days = []
      if ((this.birthday_month === '02' && this.birthday_year % 4 === 0 && this.birthday_year % 100 !== 0) || (this.birthday_month === '02' && this.birthday_year % 400 === 0)) {
        days = [...Array(29)].map((ele, i) => ((i + 1)+'').padStart(2, '0'))
      } else if (this.birthday_month === '02') {
        days = [...Array(28)].map((ele, i) => ((i + 1)+'').padStart(2, '0'))
      } else if (this.birthday_month === '04' || this.birthday_month === '06' || this.birthday_month === '09' || this.birthday_month === '11') {
        days = [...Array(30)].map((ele, i) => ((i + 1)+'').padStart(2, '0'))
      } else {
        days = [...Array(31)].map((ele, i) => ((i + 1)+'').padStart(2, '0'))
      }
      return days
    },




    week_part(){
        return this.cal_data.slice(0+this.day_counter,7+this.day_counter);
    },

    day_text(){
        // 5/3 ~ 5/9
        return this.week_part[0].day.replace(/<br>.../mg, '') +' ~ '+ this.week_part[6].day.replace(/<br>.../mg, '');
    },
    weekday(){
        return this.weekdayText[new Date(this.data.date).getDay()];
    },
    doctor_name(){
        console.log('doctor_name()');
        let text = ''
        for(let i=0; i<this.dic_doctor.length; i++){
            if( this.dic_doctor[i]['id']===this.data.doctor ){
                text = this.dic_doctor[i]['doctor'];
                break;
            }
        }
        console.log(text);
        return text
    },
},


// datas
data(){return{
    masu_selected: '',

    birthday_year: '',
    birthday_month: '',
    birthday_day: '',

    reserve_id: null,
    cancel_code: null,
    is_error: false,
    valid: false,
    current: 1,
    step_flags: {1: false, 2:false, 3:false, 4:false, 5:false, 6:false, 7:false, 8:false, 9:false, 10:false, 11:false},  // 0 step always false.

    btn_step2_disabled: true,
    btn_step3_disabled: true,

    menu1: '',
    menu2: '',
    start: ['9:00', '9:15', '9:30', '9:45', '10:00', '10:15', '10:30'],
    end: [],


    dic_doctor: [],
    shinryounaiyou: {0: false, 1: false, 2:false},

    // カレンダー
    weekdayText: ["日", "月", "火", "水", "木", "金", "土"],
    day_counter: 0,
    cal_data:[
        {req:'2022-01-01', day:'1/1<br>(土)',masu:[]},
        {req:'2022-01-02', day:'1/2<br>(日)',masu:[]},
        {req:'2022-01-03', day:'1/3<br>(月)',masu:[]},
        {req:'2022-01-04', day:'1/4<br>(火)',masu:[]},
        {req:'2022-01-05', day:'1/5<br>(水)',masu:[]},
        {req:'2022-01-06', day:'1/6<br>(木)',masu:[]},
        {req:'2022-01-07', day:'1/7<br>(金)',masu:[]},
        {req:'2022-01-08', day:'1/8<br>(土)',masu:['●', 'x', 'x', '●','●','●', 'x', '●','●','●', 'x', '●','●','●', 'x', '●','●','●', 'x', '●','●','●']},
    ],

    jikanjiku:[ '09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30'],

    def_times: [['09:00', '09:30'], ['09:30', '10:00'], ['10:00', '10:30'], ['10:30', '11:00'], ['11:00', '11:30'], ['11:30', '12:00'], ['12:00', '12:30'], ['12:30', '13:00'], ['13:00', '13:30'], ['13:30', '14:00'], ['14:00', '14:30'], ['14:30', '15:00'], ['15:00', '15:30'], ['15:30', '16:00'], ['16:00', '16:30'], ['16:30', '17:00'], ['17:00', '17:30'], ['17:30', '18:00'], ['18:00', '18:30'], ['18:30', '19:00']
    ],


    // step4
    raiin_reasons: [
        {id: 1, name: "歯が痛い・しみる・虫歯"},
        {id: 2, name: "歯茎が痛い・腫れた・血が出る"},
        {id: 3, name: "かぶせ物が痛い・取れた・壊れた"},
        {id: 4, name: "歯の抜けたところを治したい"},
        {id: 5, name: "入れ歯が壊れた"},
        {id: 6, name: "入れ歯が合わない"},
        {id: 7, name: "新しい入れ歯を作りたい"},
        {id: 8, name: "歯の掃除をしたい・歯石を取りたい"},
        {id: 9, name: "歯並びが気になる"},
        {id: 10, name: "ホワイトニングをしたい"},
        {id: 11, name: "お口の中の検査をしたい"},
        {id: 12, name: "その他"},
    ],
    shinsatu_kibou: [
        {id: 1, name: "通院回数を少なくしたい"},
        {id: 2, name: "治療中は必ず麻酔をかけて欲しい"},
        {id: 3, name: "保険の範囲で治したい"},
        {id: 4, name: "保険外の選択肢があっても良い"},
    ],
    raiin_from: [
        {id: 1, name: "家族・知人の紹介"},
        {id: 2, name: "家・保育園・会社が近い"},
        {id: 3, name: "ホームページ"},
        {id: 4, name: "その他"},
    ],

    data:{
        start: null,
        end: null,
        doctor: '',
        doctor_name: '',
        shinryounaiyou: '',
        raiin_reasons: [],
        shinsatu_kibou: [],
        hoka_tuuinn: null,
        okusuri: null,
        allergies: null,
        masui: null,
        ninshin: null,
        raiin_from: [],
        youbou: '',
        bangou: '',
        sei: '',
        mei: '',
        sei_kana: '',
        mei_kana: '',
        sex: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        birthday: null,
        tel: '',
        phone: '',
        reserve_id: null,
        mail: '',
    },


}},  // datas

async created(){
    console.log('created()');


    window.addEventListener('beforeunload', this.onBeforeunloadHandler, false);


    this.step_flags[this.current] = true;
    this.getTantoui();

    console.log('this.data', this.data);
    // キャンセルリンクから
    if( this.$route.name==='cancellink' ){
        console.log('%c========== キャンセルリンクから =====', 'background:green');
        console.log(this.$route);


        this.cancel_code = this.$route.params.cancel_code;

        let res = await axios.get('/get_reserve_id/' + this.cancel_code);
        console.log('待つよ');

        if(res.data.status!=='OK'){
            console.log('キャンセル済みです');
            this.step_flags[this.current] = false;
            this.current = 9;
            this.step_flags[9] = true;
            return false;
        }


        // 予約情報取得して反映
        axios.get('/reserve/' + res.data.data.reserve_id)
          .then((res) => {
              console.group();
              console.log('%c %s', mycolor.api, '/reserve/');
              console.log(res.data);
              console.groupEnd();


              if( !res.data ){
                  console.log('キャンセル済みです');
                  this.step_flags[this.current] = false;
                  this.current = 9;
                  this.step_flags[9] = true;
                  return false;
              }

              let ret = res.data;
              this.data = ret;
              this.data.reserve_id = res.data.id;
              console.log('this.data', this.data);

              const dateIndex = {'0900':'0', '0930':'1', '1000':'2', '1030':'3', '1100':'4', '1130':'5', '1200':'6', '1230':'7', '1300':'8', '1330':'9', '1400':'10', '1430':'11', '1500':'12', '1530':'13', '1600':'14', '1630':'15', '1700':'16', '1730':'17', '1800':'18', '1830':'19'};
              this.masu_selected = this.data.date+'_'+dateIndex[ this.data.start.replace(/:/, '')];

              this.data.doctor = this.data.doctor - 0;

              this.birthday_year = res.data.birthday.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1')
              this.birthday_month = res.data.birthday.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2')
              this.birthday_day = res.data.birthday.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3')

              let key = Object.keys(['治療', '検診・クリーニング', 'インプラント・矯正等の相談']).find(k => ['治療', '検診・クリーニング', 'インプラント・矯正等の相談'][k]===ret.shinryounaiyou);
              this.btn_step2_disabled = false;
              this.shinryounaiyou[key] = true;

              let date_text =  ret.date + ' ' + ret.start ;
              let this_date = new Date( date_text );
              let diff = ( this_date.getTime() - new Date().getTime() )/(1000*60*60);
              console.log(diff);
              if( diff  < 1 ){
                  console.log('締め切りを過ぎてます');
                  this.step_flags[this.current] = false;
                  this.current = 10;
                  this.step_flags[10] = true;
              }
          })
          .catch( e => {console.log(e)});
    }
},  // created()

methods:{

    confirm() {
      return window.confirm('ページを離脱してもよろしいですか？');
    },

    onBeforeunloadHandler(e) {
        e.returnValue = 'ページを離脱してもよろしいですか？';
    },

    print_svg(v, myid){
        let svg = '/img/batu.svg';
        if(v=='●'){
            if(this.masu_selected!==myid){
                svg = '/img/maru.svg';
            }else{
                svg = '/img/maru_white.svg';
            }
        }else if(v=='▲'){
            if(this.masu_selected!==myid){
                svg = '/img/sankaku.svg';
            }else{
                svg = '/img/sankaku_white.svg';
            }
        }else if(v=='x'){
            if(this.masu_selected!==myid){
                svg = '/img/batu.svg';
            }else{
                svg = '/img/batu_white.svg';
            }
        }

        return svg;
    },
    do_birthday(){
        console.log('do_birthday()');
        console.log('birthday: ', this.data.birthday);
        console.log('birthday_year: ', this.birthday_year);
        console.log('birthday_month: ', this.birthday_month);
        console.log('birthday_day: ', this.birthday_day);
        if( this.data.bangou.trim()!=='' && this.birthday_year!=='' && this.birthday_month!=='' && this.birthday_day!=='' ){
            this.data.birthday = this.birthday_year + '-' + this.birthday_month + '-' + this.birthday_day;
            console.log('birthday: ', this.data.birthday);
            this.check_patient();
        }
    },
    do_mail(){
        console.log('do_mail()');
        console.log('bangou: ', this.data.bangou);
        console.log('mail: ', this.data.mail);
        if( this.data.bangou.trim()  && this.data.mail.trim()   ){
            this.check_patient();
        }
    },
    check_patient(){
        console.log('check_patient');


        this.data.bangou = this.data.bangou.trim();
        this.data.birthday = this.data.birthday.trim();

        axios.post( '/get_patient_by_bangou', {'bangou': this.data.bangou, 'birthday': this.data.birthday})
          .then((res) => {
              console.group();
              console.log('%c %s', mycolor.api, '/get_patient_by_bangou');
              console.log(res.data);
              console.groupEnd();

              var data = res.data;
              if( data ){
                  alert('該当する患者情報が見つかりました。');
                  this.data.sei = data.sei;
                  this.data.mei = data.mei;
                  this.data.sei_kana = data.sei_kana;
                  this.data.mei_kana = data.mei_kana;
                  this.data.sex = data.sex;
                  this.data.tel = data.tel;
                  this.data.phone = data.phone;
                  this.data.mail = data.mail;
              }

          })
          .catch((error) => {
            console.error(error);
            return false;
          });

    },
    pre_cancel(){
        console.log('pre_cancel()');
        this.step_flags[this.current] = false;
        this.current = 11;
        this.step_flags[this.current] = true;
    },


    cancel(){
        console.log('cancel()');

        axios.get( '/reserve_cancel/' + this.data.reserve_id )
          .then((res) => {
              console.group();
              console.log('%c %s', mycolor.api, '/reserve_cancel');
              console.log(res.data);
              console.groupEnd();

              // ステップ更新ではなく、静的ページにredirectのほうが良い.
              this.step_flags[this.current] = false;
              this.current = 8;
              this.step_flags[this.current] = true;
          })
          .catch( (error) => {
              console.error(error);

              this.step_flags[this.current] = false;
              this.current = 8;
              this.step_flags[this.current] = true;
          });
    },

    kyou(){
        console.log('kyou()');
        this.day_counter = 0;
    },
    modoru(){
        console.log('modoru()');
        console.log(this.day_counter);
        if(this.day_counter>0){
            this.day_counter -= 7;
        }
    },
    susumu(){
        console.log('susumu()');
        console.log(this.day_counter);
        if(this.day_counter<21){
            this.day_counter += 7;
        }
    },
    masuClick(date, week_i, index, v, e, child_fire){
        console.group();
        console.log('masuClick(): ');
        console.log('date: ' + date);
        console.log('week_i: ' + week_i);
        console.log('index: ' + index);
        console.log('v: ' + v);
        console.log(e.target);
        console.log('masu_selected', this.masu_selected);
        console.groupEnd();

        var mytarget = e.target;
        if(child_fire){
            mytarget = e.target.parentElement;
        }
        console.log('mytarget', mytarget);

        if(v==='x' || v==='TEL' || v==='休'){
            return false;
        }

        // data反映
        this.data.date = date;
        this.data.start = this.def_times[index][0];
        this.data.end = this.def_times[index][1];
        this.btn_step3_disabled = false;  // ボタンを有効化
        this.masu_selected = mytarget.id;

        return false;
    },

    submit(){
        console.log('submit()');
        this.data.doctor_name = this.doctor_name;
        console.log(this.data);

        let valid = this.$refs.form.validate()
        console.log(valid);
        if(!valid){
            return false;
        }

        if(this.cancel_code){
            this.data.is_cancellink = true;
        }


        if(this.current==6){
            axios.post('/reserve_touroku_web', this.data)
              .then((res) => {
                  console.group();
                  console.log('%c %s', mycolor.api, '/reserve_touroku_web');
                  console.log(res.data);
                  console.groupEnd();

                    if(res.data.status==='OK'){
                        // stepを進める
                        this.goStep();


                        // 予約確認メールを送信
                        axios.get('/send_yoyaku_kakunin_mail/'+res.data.reserve_id)
                          .then((res) => {
                              console.group();
                              console.log('%c %s', mycolor.api, '/send_yoyaku_kakunin_mail');
                              console.log('予約確認メール送信完了');
                              console.log(res.data);
                              console.groupEnd();
                          });

                    }else{
                        alert('予約が取れませんでした。\n申し訳ございませんが、再度日時を選択してお試しください。');
                        return false;
                    }
              })
              .catch( (error) => {
                    alert('予約が取れませんでした。\n申し訳ございませんが、再度日時を選択してお試しください。');
                    return false;
              });

        }else{
            // stepを進める
            this.goStep();
        }

    },
    goStep(){
        // stepを進める
        console.log('goStep()');

        this.step_flags[this.current] = false;
        this.current++;
        this.step_flags[this.current] = true;
        window.scrollTo(0,0);
        console.log(this.step_flags);

        // カレンダーを取得
        if( this.current==2 ){
            console.log('data.doctor: '+this.data.doctor);
            this.getCalendar();
        }
    },

    back(){
        console.log(this.current);
        // step11 キャンセル確認画面からだったら
        if(this.current===11){

            this.step_flags[this.current] = false;
            this.current = 1;
            this.step_flags[1] = true;
            return false;
        }
        this.step_flags[this.current] = false;
        this.current--;
        this.step_flags[this.current] = true;
        console.log(this.step_flags);
    },
    toggleClass(index){
        console.log('toggleClass()');
        this.shinryounaiyou = {1: false, 2: false, 3:false};
        this.shinryounaiyou[index] = true;
        this.data.shinryounaiyou = ['治療', '検診・クリーニング', 'インプラント・矯正等の相談'][index];
        this.btn_step2_disabled = false;
    },
    getTantoui(){
        console.log('getTantoui()');

            // 担当医のリスト取得
            axios.get('/get_doctors')
              .then((res) => {
                  console.group();
                  console.log('%c %s', mycolor.api, '/get_doctors');
                  console.log('担当医のリスト取得');
                  console.log(res.data);
                  console.groupEnd();

                  this.dic_doctor = res.data;
              })
              .catch((error) => {
                console.error(error);
              });

    },  // getTantoui()
    getBirthdayText(){

        this.data.birthday = this.birthday_year + '-' + this.birthday_month + '-' + this.birthday_day;
        let re = /(\d+)-(\d+)-(\d+)/;
        let newstr = this.data.birthday.replace(re, '$1年$2月$3日');
        console.log(`birthday: ${newstr}`);
        return newstr

    },
    getCalendar(){
        console.log('getCalendar()');
        axios.post('/get_calendar', {'doctor_id': this.data.doctor})
          .then((res) => {
              console.group();
              console.log('%c %s', mycolor.api, '/get_calendar');
              console.log('カレンダー取得完了')
              console.log(res.data);
              console.groupEnd();

              this.cal_data = res.data;
          })
          .catch((error) => {
            console.error(error);
          });

    },
    toTop(){
        // 離脱イベントを削除
        window.removeEventListener('beforeunload', this.onBeforeunloadHandler, false);
        location.href = 'https://nishiharacb-dentalclinic.com/';
    }
},  // methods

}
</script>

<style scoped>
.main{
    border: 1px solid #888;
    max-width: 400px !important;  /* iPad Air の解像度 */
    padding: 30px !important;
    height: 100%;
    min-height: 100vh;
}
.step3{
    border: 1px solid #888;
    max-width: 400px !important;  /* iPad Air の解像度 */
    padding: 25px !important;
}
.calendar{
    max-height: 510px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.day_text{
    font-size: 1.3rem;
}
.oneday{
    padding: 0px !important;
    border-right: 1px solid #8CC11E;
}
.jikanjiku{
    padding: 0px;
    border-left: 1px solid #8CC11E;
    border-right: 1px solid #8CC11E;
}
.day{
    font-size: 12px;
    padding-top: 4px;
    height: 45px;
    border-top: 1px solid #8CC11E;
    border-bottom: 1px solid #8CC11E;
}
.masu{
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #8CC11E;
}
.active{
    color: #fff;
    background: #8CC11E;
}
.h1{
    font-size: 16px;
    font-weight: bold;
}
.title{
    font-weight: bold !important;
}
.white_box{
    color: #8CC11E;
    border: 1px solid #8CC11E;
    padding: 16px;
}
.white_box__active{
    color: #fff;
    border: 1px solid #8CC11E;
    padding: 16px;
    background: #8CC11E;
}
.midasi{
    border-radius: 8px;
    background: #8CC11E;
    padding: 8px;
    font-size: 14px !important;
}
.text_green{
    color: #8CC11E;
}
.text_green label{
    color: #8CC11E !important;
}
.text_green .v-input{
    margin:0 !important;
}

.confirmation{
    font-size: 14px;
    border: 1px solid #888;
    max-width: 400px !important;  /* iPad Air の解像度 */
    padding: 15px !important;
}
.v-btn{
    width: 210px;
    min-width: 210px;
}
.v-btn:not(.v-btn--round).v-size--default{
    min-width: unset !important;
}
.kyoubtn{
    min-width: unset !important;
    width: unset !important;
}
/* メインのグリーン */
.v-application .mygreen {
    background-color: #8CC11E !important;
    border-color: #8CC11E !important;
}
.v-btn > .v-btn__content .v-icon{
    margin: 0 !important;
}
.masu img{
    height: 50%;
    vertical-align: middle;
}
.icon_svg{
    width: 6%;
    vertical-align: top;
}
/deep/ .mybirth div.v-input__slot{
    background: white !important;
    border: 1px #00000061 solid !important;
    border-radius: 4px !important;
}
/deep/ .mybirth .v-input__slot:before{
    border: none;
}
</style>

/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios';
/* eslint-disable */

Vue.config.productionTip = false

// グローバル変数
// 予約チップの色
Vue.prototype.$colors = ['#FFFFFF', '#F8F1B2', '#DBE6F6', '#C3E8C5', '#E2E1E1', '#FFD2D0'];


if( location.hostname==='localhost' || location.hostname==='192.168.68.51' || location.hostname==='133.130.90.136' ) {
    // 開発環境
    Vue.prototype.$fastapi = 'http://' + location.hostname + ':9000/api';
    Vue.prototype.$websocket = 'ws://' + location.hostname + ':9000/api/chat';
    Vue.prototype.$base_url = 'http://' + location.hostname + ':8000/'
    Vue.prototype.$interval_time = 30000;
    Vue.prototype.$is_dev = true;
    console.log('main.js: is_dev: true');
}else{
    // 本番環境
    Vue.prototype.$fastapi = 'https://'+location.hostname+'/api';
    Vue.prototype.$websocket = 'wss://'+location.hostname+'/api/chat';
    Vue.prototype.$base_url = 'https://'+location.hostname+':82/'
    Vue.prototype.$interval_time = 30000;
    Vue.prototype.$is_dev = false;
}


// axiosの共通設定
axios.defaults.baseURL = Vue.prototype.$fastapi;



new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

const mycolor = {
    created: 'background:purple; font-size:20px;',
    strong: 'background:DeepSkyBlue; font-size:20px;',
    api: 'background: Orange; color:black',
    orange: 'background: DarkOrange; color: #222',
    green: 'background: Chartreuse; color: #222',
    pink: 'background: pink; font-size: 20px; color:black'
}
export default mycolor

